import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';

import { FormattedMessage } from 'dibs-react-intl';

import { Badge } from 'dibs-elements/exports/Badge';
import { useAuctionState } from 'dibs-auctions/exports/hooks';
import { useAuctionItemPriceDetails } from '../../hooks/useAuctionItemPriceDetails';

import { AuctionItemPriceDetails_item$key } from './__generated__/AuctionItemPriceDetails_item.graphql';

import styles from './styles/AuctionItemPriceDetails.scss';

export type Props = {
    item: AuctionItemPriceDetails_item$key | null | undefined;
    isBoldText?: boolean;
    withBadge?: boolean;
    withBidInfo?: boolean;
    currency?: string;
};

const AuctionItemPriceDetails: FC<Props> = ({
    item: itemRef,
    isBoldText,
    withBadge = true,
    withBidInfo = true,
    currency,
}) => {
    const item = useFragment(
        graphql`
            fragment AuctionItemPriceDetails_item on Item
            @argumentDefinitions(page: { type: "PageDisplayEnum", defaultValue: null }) {
                ...useAuctionState_item @arguments(page: $page)
                ...useAuctionItemPriceDetails_item @arguments(page: $page)
                draftAuctionLot {
                    status
                }
                relevantAuctionLot(page: $page) {
                    status
                }
                relevantAuctionSolution(page: $page) {
                    maskedHighestBidder
                }
            }
        `,
        itemRef
    );
    const { draftAuctionLot, relevantAuctionLot, relevantAuctionSolution } = item || {};
    const { buyItNowAmount, currentBidAmount, reservePriceAmount, startingBidAmount } =
        useAuctionItemPriceDetails({ item, currency });

    const { hasAuctionEndedWithWinner } = useAuctionState({ item });

    const { maskedHighestBidder } = relevantAuctionSolution || {};
    const priceTextClass = isBoldText ? styles.label : undefined;
    const auctionLot = relevantAuctionLot || draftAuctionLot;
    const isOpenWithBid = auctionLot?.status === 'OPEN' && maskedHighestBidder;

    return (
        <>
            {withBadge && (
                <Badge type="pill" dataTn="iim-auction-badge">
                    <FormattedMessage
                        defaultMessage="Auction item"
                        id="aai.iim.AuctionItemPriceDetails.auctionItemBadge"
                    />
                </Badge>
            )}
            <div className={styles.wrapper}>
                {!!buyItNowAmount.value && (
                    <div className={styles.textWrapper}>
                        <span
                            className={priceTextClass}
                            data-tn="iim-auction-buy-it-now-price-label"
                        >
                            <FormattedMessage
                                defaultMessage="Buy It Now Price: "
                                id="aai.iim.AuctionItemPriceDetails.buyItNowPrice"
                            />
                        </span>
                        <span data-tn="iim-auction-buy-it-now-price">
                            {buyItNowAmount.formatted}
                        </span>
                    </div>
                )}
                <div className={styles.textWrapper}>
                    <span className={priceTextClass} data-tn="iim-auction-starting-price-label">
                        <FormattedMessage
                            defaultMessage="Auction Starting Price: "
                            id="aai.iim.AuctionItemPriceDetails.startingPrice"
                        />
                    </span>
                    <span data-tn="iim-auction-starting-price">{startingBidAmount.formatted} </span>
                </div>
                <div className={styles.textWrapper}>
                    {reservePriceAmount.value ? (
                        <>
                            <span
                                className={priceTextClass}
                                data-tn="iim-auction-reserve-price-label"
                            >
                                <FormattedMessage
                                    defaultMessage="Reserve Price: "
                                    id="aai.iim.AuctionItemPriceDetails.reservePrice"
                                />
                            </span>
                            <span data-tn="iim-auction-reserve-price">
                                {reservePriceAmount.formatted}
                            </span>
                        </>
                    ) : (
                        <span className={priceTextClass} data-tn="iim-auction-no-reserve-price">
                            <FormattedMessage
                                defaultMessage="No Reserve "
                                id="aai.iim.auctionItemDetails.noReserve"
                            />
                        </span>
                    )}
                </div>
                {isOpenWithBid && withBidInfo ? (
                    <div className={styles.textWrapper}>
                        <span
                            className={priceTextClass}
                            data-tn="iim-auction-current-bid-price-label"
                        >
                            <FormattedMessage
                                defaultMessage="Current Bid Price: "
                                id="aai.iim.auctionItemDetails.currentBidPrice"
                            />
                        </span>
                        <span data-tn="iim-auction-current-bid-price">
                            {currentBidAmount.formatted}
                        </span>
                    </div>
                ) : null}
                {hasAuctionEndedWithWinner && withBidInfo ? (
                    <div className={styles.textWrapper}>
                        <span
                            className={priceTextClass}
                            data-tn="iim-auction-winning-bid-price-label"
                        >
                            <FormattedMessage
                                defaultMessage="Winning Bid Price: "
                                id="aai.iim.AuctionItemPriceDetails.winningBid"
                            />
                        </span>
                        <span data-tn="iim-auction-winning-bid-price">
                            {currentBidAmount.formatted}
                        </span>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default AuctionItemPriceDetails;
