/**
 * @generated SignedSource<<40f984041339558e6a7cc7d47188fa52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AuctionLotStatus = "CANCELED" | "CLOSED" | "DELETED" | "DRAFT" | "OPEN" | "PAUSED" | "SCHEDULED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AuctionItemPriceDetails_item$data = {
  readonly draftAuctionLot: {
    readonly status: AuctionLotStatus | null;
  } | null;
  readonly relevantAuctionLot: {
    readonly status: AuctionLotStatus | null;
  } | null;
  readonly relevantAuctionSolution: {
    readonly maskedHighestBidder: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useAuctionItemPriceDetails_item" | "useAuctionState_item">;
  readonly " $fragmentType": "AuctionItemPriceDetails_item";
};
export type AuctionItemPriceDetails_item$key = {
  readonly " $data"?: AuctionItemPriceDetails_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionItemPriceDetails_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionItemPriceDetails_item",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useAuctionState_item"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useAuctionItemPriceDetails_item"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionLot",
      "kind": "LinkedField",
      "name": "draftAuctionLot",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "AuctionLot",
      "kind": "LinkedField",
      "name": "relevantAuctionLot",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "AuctionSolution",
      "kind": "LinkedField",
      "name": "relevantAuctionSolution",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maskedHighestBidder",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "064a946ad0d7a4d13b43eab575bbef0b";

export default node;
