import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';
import { FC, lazy } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Props as AuctionItemPriceDetailsProps } from './AuctionItemPriceDetails';

import { AuctionItemPriceDetailsLazy_item$key as Item } from './__generated__/AuctionItemPriceDetailsLazy_item.graphql';

const AuctionItemPriceDetails = lazy(
    () => import(/* webpackChunkName: "AuctionItemPriceDetails" */ './AuctionItemPriceDetails')
);

const itemFragment = graphql`
    fragment AuctionItemPriceDetailsLazy_item on Item
    @argumentDefinitions(page: { type: "PageDisplayEnum", defaultValue: null }) {
        ...AuctionItemPriceDetails_item @arguments(page: $page)
    }
`;

type Props = {
    item: Item;
} & Omit<AuctionItemPriceDetailsProps, 'item'>;

const AuctionItemPriceDetailsLazy: FC<Props> = ({ item: itemRef, ...props }) => {
    const item = useFragment(itemFragment, itemRef);

    return (
        <ClientSuspense fallback={null}>
            <AuctionItemPriceDetails item={item} {...props} />
        </ClientSuspense>
    );
};

export default AuctionItemPriceDetailsLazy;
