import { Fragment, FC, MouseEvent, ElementType, useContext } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';
import { AuctionItemPriceDetailsLazy } from 'dibs-auctions/exports/AuctionItemPriceDetails';
import { OriginalListPriceLazy, PUB_TOOLS_KEY } from 'dibs-auctions/exports/OriginalListPrice';

import { Price } from '../Price';
import Title from '../Title';
import CreatedBy from './CreatedBy';
import OfferedBy from './OfferedBy';
import InternalSellerName from './InternalSellerName';
import { TileContext } from '../helpers/TileContext';
import { CREATOR_UNKNOWN } from '../constants';

import { Info_item$data as InfoItemProp } from './__generated__/Info_item.graphql';
import { Info_seller$data as InfoSellerProp } from './__generated__/Info_seller.graphql';
import { Info_viewer$data as InfoViewerProp } from './__generated__/Info_viewer.graphql';

import styles from './styles.scss';
import { Shipping } from '../Badges/Shipping';
import InaTransactionsBadge from '../Badges/InternalAttributes/InaTransactionsBadge';
import MeetsRecommendedPricingBadge from '../Badges/InternalAttributes/MeetsRecommendedPricingBadge';
import { SkusBadge } from 'dibs-branded-badges/exports/multisku/SkusBadge';
import ItemShippingPrequotesLazy from '../ItemShippingPrequotes/ItemShippingPrequotesLazy';

import dibsCss from 'dibs-css';

const CREATOR_ATTRIBUTED_BY = 'BY';
const CREATOR_ATTRIBUTED_BY_AND_DOCUMENTED = 'BY_AND_DOCUMENTED';

const CREATOR_ATTRIBUTION_LIST = [CREATOR_ATTRIBUTED_BY, CREATOR_ATTRIBUTED_BY_AND_DOCUMENTED];

type InfoComponentProps = {
    viewer: InfoViewerProp | null;
    item: InfoItemProp;
    currency: string;
    titleClassName?: string;
    seller?: InfoSellerProp | null;
    disableLinks?: boolean;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    listingStatus?: string;
    showInternalSellerName?: boolean;
    showSkusBadge?: boolean;
    showByLine?: boolean;
    showSellerName?: boolean;
    showCreatorName?: boolean;
    showPrice?: boolean;
    showShippingPrequotes?: boolean;
    showAuctionItemPriceDetails?: boolean;
    showOriginalListPrice?: boolean;
    showTitle?: boolean;
    showShippingBadge?: boolean;
    showInaTransactionsBadge?: boolean;
    showMeetsPricingBadge?: boolean;
    priceBold?: boolean;
    isSbTile?: boolean;
    titleOneLine?: boolean;
    Heading?: ElementType;
    pdpUrl?: string;
};

export const InfoComponent: FC<InfoComponentProps> = props => {
    const {
        titleClassName = '',
        currency = 'USD',
        disableLinks = false,
        showInternalSellerName = false,
        listingStatus = '',
        showByLine = true,
        showSellerName = true,
        showCreatorName = false,
        showShippingBadge = true,
        showPrice = true,
        showShippingPrequotes = false,
        showAuctionItemPriceDetails = false,
        showOriginalListPrice = false,
        showInaTransactionsBadge = false,
        showMeetsPricingBadge = false,
        showTitle = true,
        priceBold = false,
        isSbTile = false,
        titleOneLine = false,
        showSkusBadge = false,
        Heading = 'h2',
        pdpUrl,
    } = props;

    const { imageSize } = useContext(TileContext);

    const item = props?.item || {};
    const creationDate = item.classification?.creationDate || null;
    const creatorName = item.creators?.[0]?.creator?.displayName || null;
    const url = pdpUrl || item.localizedPdpUrl || null;
    const title = item.title || null;
    const isArt = item.vertical === 'art';

    // Seller Info
    const sellerName = props?.seller?.sellerProfile?.company || '';
    const shouldShowSellerName = !showCreatorName && showSellerName && !!sellerName;
    const shouldShowInternalSellerName = showInternalSellerName && !!sellerName;

    // Creator Attribution
    const creators = item.creators;
    const attribution =
        creators &&
        creators.find(
            el => el && el.attribution && CREATOR_ATTRIBUTION_LIST.includes(el.attribution)
        );
    const attributionCreatorName = attribution?.creator?.displayName || '';
    const shouldShowCreatorName =
        showCreatorName &&
        !!attributionCreatorName &&
        attributionCreatorName.toLowerCase() !== CREATOR_UNKNOWN;

    return (
        <Fragment>
            {shouldShowInternalSellerName && (
                <InternalSellerName sellerName={sellerName} listingStatus={listingStatus} />
            )}
            {(showInaTransactionsBadge || showMeetsPricingBadge) && (
                <div className={classnames(dibsCss.flex, dibsCss.gapX3px)}>
                    {showInaTransactionsBadge && (
                        <InaTransactionsBadge
                            seller={item?.seller}
                            wrapperClass={dibsCss.mbXsmall}
                        />
                    )}
                    {showMeetsPricingBadge && (
                        <MeetsRecommendedPricingBadge item={item} wrapperClass={dibsCss.mbXsmall} />
                    )}
                </div>
            )}
            <div
                className={classnames(styles.titleWrapper, {
                    [styles.hasByLine]: showByLine,
                    [styles.titleOneLine]: titleOneLine,
                })}
            >
                {showTitle && url && (
                    <Title
                        creationDate={creationDate}
                        creatorName={creatorName}
                        disableLinks={disableLinks}
                        isArt={isArt}
                        pdpUrl={url}
                        title={title}
                        onClick={props.onClick}
                        titleOneLine={titleOneLine}
                        titleClassName={titleClassName}
                        Heading={Heading}
                    />
                )}
                {showByLine && (
                    <div
                        className={classnames(
                            styles.byline,
                            // 'large' is the only `imageSize` in the
                            // styles associated with this component
                            imageSize === 'imageXLarge' && styles[imageSize]
                        )}
                    >
                        {shouldShowCreatorName && (
                            <CreatedBy creatorName={attributionCreatorName} />
                        )}
                        {shouldShowSellerName && <OfferedBy sellerName={sellerName} />}
                    </div>
                )}
            </div>
            {showPrice && (
                <Price
                    currency={currency}
                    item={props.item}
                    priceBold={priceBold}
                    isSbTile={isSbTile}
                />
            )}
            {showOriginalListPrice && (
                <OriginalListPriceLazy
                    item={item}
                    currency={currency}
                    type={PUB_TOOLS_KEY} // visible only in pub tools
                    className={styles.originalListPrice}
                />
            )}
            {showAuctionItemPriceDetails && (
                <AuctionItemPriceDetailsLazy
                    item={props.item || null}
                    withBadge={false}
                    withBidInfo={false}
                    currency={currency}
                />
            )}
            {showShippingPrequotes && <ItemShippingPrequotesLazy item={item} isLabelBold={false} />}
            {showShippingBadge && (
                <Shipping item={props.item} viewer={props.viewer} currency={currency} />
            )}
            {showSkusBadge && (
                <div className={styles.skusBadge}>
                    <SkusBadge />
                </div>
            )}
        </Fragment>
    );
};

export const Info = createFragmentContainer(InfoComponent, {
    viewer: graphql`
        fragment Info_viewer on Viewer
        @argumentDefinitions(
            fetchRegionalInfo: { type: "Boolean", defaultValue: false }
            userZipCode: { type: "String", defaultValue: "" }
            userCountryCode: { type: "String", defaultValue: "" }
        ) {
            ...Shipping_viewer
                @arguments(
                    fetchRegionalInfo: $fetchRegionalInfo
                    userZipCode: $userZipCode
                    userCountryCode: $userCountryCode
                )
        }
    `,
    item: graphql`
        fragment Info_item on Item
        @argumentDefinitions(
            isTrade: { type: "Boolean", defaultValue: false }
            pageDisplayEnum: { type: "PageDisplayEnum", defaultValue: searchAndBrowse }
            showPrice: { type: "Boolean", defaultValue: true }
            priceBookName: { type: "String" }
            isInternalAdmin: { type: "Boolean", defaultValue: false }
        ) {
            ...ItemShippingPrequotesLazy_item @include(if: $isInternalAdmin)
            ...AuctionItemPriceDetailsLazy_item
                @arguments(page: $pageDisplayEnum)
                @include(if: $isInternalAdmin)
            ...OriginalListPriceLazy_item @arguments(page: $pageDisplayEnum)
            ...Price_item
                @arguments(
                    pageDisplayEnum: $pageDisplayEnum
                    isTrade: $isTrade
                    showPrice: $showPrice
                    priceBookName: $priceBookName
                )
            ...Shipping_item
            ...MeetsRecommendedPricingBadge_item @include(if: $isInternalAdmin)
            classification {
                creationDate
            }
            creators {
                attribution
                creator {
                    displayName
                }
            }
            localizedPdpUrl
            title
            vertical
            # seller is needed for internal item attribute
            # and additional seller fragment below is dependant on $showSeller argument
            # item attributes should only depend on $isInternalAdmin argument
            seller @include(if: $isInternalAdmin) {
                ...InaTransactionsBadge_seller
            }
        }
    `,
    seller: graphql`
        fragment Info_seller on Seller {
            sellerProfile {
                company
            }
        }
    `,
});
