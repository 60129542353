/**
 * @generated SignedSource<<ce8a2a3bc16216e1714ec2477321fda7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerticalType = "art" | "fashion" | "furniture" | "jewelry" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Info_item$data = {
  readonly classification: {
    readonly creationDate: string | null;
  } | null;
  readonly creators: ReadonlyArray<{
    readonly attribution: string | null;
    readonly creator: {
      readonly displayName: string | null;
    } | null;
  } | null> | null;
  readonly localizedPdpUrl: string | null;
  readonly seller?: {
    readonly " $fragmentSpreads": FragmentRefs<"InaTransactionsBadge_seller">;
  } | null;
  readonly title: string | null;
  readonly vertical: VerticalType | null;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionItemPriceDetailsLazy_item" | "ItemShippingPrequotesLazy_item" | "MeetsRecommendedPricingBadge_item" | "OriginalListPriceLazy_item" | "Price_item" | "Shipping_item">;
  readonly " $fragmentType": "Info_item";
};
export type Info_item$key = {
  readonly " $data"?: Info_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"Info_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "pageDisplayEnum"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isInternalAdmin"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isTrade"
    },
    {
      "defaultValue": "searchAndBrowse",
      "kind": "LocalArgument",
      "name": "pageDisplayEnum"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceBookName"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "showPrice"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Info_item",
  "selections": [
    {
      "condition": "isInternalAdmin",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ItemShippingPrequotesLazy_item"
        },
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "AuctionItemPriceDetailsLazy_item"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MeetsRecommendedPricingBadge_item"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Seller",
          "kind": "LinkedField",
          "name": "seller",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "InaTransactionsBadge_seller"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "OriginalListPriceLazy_item"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        {
          "kind": "Variable",
          "name": "pageDisplayEnum",
          "variableName": "pageDisplayEnum"
        },
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        },
        {
          "kind": "Variable",
          "name": "showPrice",
          "variableName": "showPrice"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Price_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Shipping_item"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemClassification",
      "kind": "LinkedField",
      "name": "classification",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creationDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemCreator",
      "kind": "LinkedField",
      "name": "creators",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attribution",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Creator",
          "kind": "LinkedField",
          "name": "creator",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedPdpUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vertical",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "57824548af67ed563cb2da84bcd8872f";

export default node;
